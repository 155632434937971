import styled from 'styled-components';
import bg from '../../images/case_study_bg.png';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import shadow from '../../styles/atoms/shadows';
import { font } from '../../styles/atoms/typography';

export const HeaderContainer = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70.57%, #FFFFFF 70.57%, #FFFFFF 100%), url(${bg});
  background-repeat: no-repeat;
  background-position: center top;

  .content {
    margin: 0 auto;
    padding-left: 23.5px;
    padding-right: 23.5px;

    ${atMinWidth.sm`
      padding-left: 56px;
      padding-right: 56px;
    `}

    ${atMinWidth.md`
      max-width: 860px;
    `}

    ${atMinWidth.lg`
      max-width: 990px;
      padding-left: 15px;
      padding-right: 15px;
    `}

    ${atMinWidth.xl`
      max-width: 1200px;
    `}
  }
`;

export const ContentWrapper = styled.div`
  .section > * {
    padding-top: 0;

    ${atMinWidth.xl`
      padding-top: 80px;
    `}
  }

  .case-study-content {
    padding-top: 40px;

    ${atMinWidth.lg`
      padding-top: 0;
    `}

    h2 {
      + p strong {
        color: ${colors.primary[600]};
      }
    }

    figure {
      border-radius: 8px;
      overflow: hidden;
    }

    blockquote {
      padding: 32px;
      background-color: ${colors.primary[50]};
      border-radius: 8px;
      overflow: hidden;
      margin: 16px 0;
      position: relative;

      ${atMinWidth.md`
        padding: 48px;
        margin: 32px 0;
      `}

      .wave {
        position: static;
        display: none;

        ${atMinWidth.md`
          display: block;
        `}

        svg {
          position: absolute;
          top: initial;
          right: 100px;
          bottom: -85px;
          overflow: visible;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .quote {
        ${font('display', 'xs', '700')}
        line-height: 150%;

        * {
          font-weight: 700 !important;
          color: ${colors.gray[900]};
          margin: 0;
        }

        strong {
          color: ${colors.primary[600]};
        }

        ${atMinWidth.md`
          ${font('display', 'sm', '700')}
          line-height: 150%;
        `}
      }
    }
  }

  .result-content-wrapper {
    display: flex;
    align-items: center;

    .result-title {
      margin-bottom: 0;
    }

    .image-wrapper {
      margin-right: 16px;

      & img {
        width: 35px;
        height: 39px;
      }
    }
  }

  .sidebar {
    ${font('display', 'sm', '700')}

    ${atMinWidth.lg`
      position: sticky;
      top: 150px;
    `}

    .heading {
      margin-bottom: 32px;
      display: none;

      ${atMinWidth.lg`
        display: block;
      `}
    }

    .card {
      ${font('text', 'xs', '400')}
      color: ${colors.gray[600]};
      background-color: ${colors.base.light};
      border: 1px solid ${colors.gray[100]};
      border-radius: 8px;
      padding: 32px;

      ${atMinWidth.md`
        ${font('text', 'sm', '400')}
        margin-right: 20px;
      `}

      ${atMinWidth.xl`
        margin-right: 70px;
      `}
    }

    .field {
      + .field {
        margin-top: 16px;
      }

      .label {
        color: ${colors.gray[700]};
        font-weight: 700;
        margin-bottom: 4px;

        ${atMinWidth.lg`
          margin-bottom: 8px;
        `}
      }

      .value {
        line-height: 24px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            + li {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
`;

export const ResultsWrapper = styled.div`
  color: ${colors.base.white};
  position: relative;
  overflow: hidden;

  .hexes {
    svg {
      transform: rotateZ(10deg);
      transform-origin: center;
      width: 110vw;
      height: 110vw;

      ${atMinWidth.sm`
        width: 42vw;
        height: 42vw;
      `}

      ${atMinWidth.lg`
        width: 35vw;
        height: 35vw;
      `}

      &:first-child {
        left: -60vw;
        top: -45vw;

        ${atMinWidth.sm`
          left: -20vw;
          top: -18vw;
        `}
      }

      &:last-child {
        top: initial;
        right: -75vw;
        bottom: -40vw;

        ${atMinWidth.sm`
          right: -24vw;
          bottom: -18vw;
        `}
      }
    }
  }

  .heading {
    ${font('display', 'md', '700')}
    text-align: center;
    margin: 32px auto 0;

    ${atMinWidth.lg`
      ${font('display', 'xl', '700')}
    `}
  }

  .results {
    list-style: none;
    margin: 40px auto 0;

    ${atMinWidth.sm`
      margin-top: 56px;
    `}

    ${atMinWidth.md`
      margin-top: 56px;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 30px;
    `}

    ${atMinWidth.xl`
      margin-top: 64px;
    `}

    > li + li {
      margin-top: 32px;

      ${atMinWidth.sm`
        margin-top: 0;
      `}
    }

    .icon {
      display: block;
      width: 40px;
      height: 40px;
    }

    .result-heading {
      ${font('text', 'md', '700')}
      margin-top: 12px;

      ${atMinWidth.lg`
        ${font('text', 'xl', '700')}
        margin-top: 16px;
      `}
    }

    .result-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: ${colors.gray[200]};
      margin-top: 12px;

      ${atMinWidth.md`
        ${font('text', 'md', '400')}
      `}

      a {
        color: ${colors.green[500]};

        &:hover {
          color: ${colors.green[300]};
        }
      }

      div {
        display: contents;
      }
    }
  }
`;

export const AboutWrapper = styled.div`
  background: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  .heading {
    ${font('display', 'md', '700')}
    color: ${colors.gray[900]};
    text-align: center;
    max-width: 770px;
    margin: 0 auto;

    ${atMinWidth.md`
      margin-top: 32px;
    `}

    ${atMinWidth.lg`
      ${font('display', 'xl', '700')}
    `}
  }

  .about-content {
    ${font('text', 'md', '400')}
    color: ${colors.gray[600]};
    text-align: center;
    margin-top: 32px;

    ${atMinWidth.md`
      ${font('text', 'xl', '400')}
    `}

    p {
      max-width: 770px;
      margin-left: auto;
      margin-right: auto;

      &:has(.video, figure) {
        max-width: 100%;
      }
    }
  }

  .video,
  figure {
    margin-top: 40px;
    margin-bottom: 0;

    ${atMinWidth.md`
      margin-top: 56px;
    `}

    ${atMinWidth.lg`
      margin-top: 74px;
    `}

  }

  .video {
    img {
      max-height: initial;
    }

    button {
      border-radius: 16px;
      ${shadow('lg')}

      ${atMinWidth.md`
        ${shadow('xxl')}
      `}
    }
  }

  figure {
    border-radius: 16px;
    overflow: hidden;
      ${shadow('lg')}

      ${atMinWidth.md`
        ${shadow('xxl')}
      `}
  }
`;

export const ConversionPanelWrapper = styled.div`
  #hub-form .hs-submit input {
    background-color: ${colors.primary[500]};
  }
`;
